import React, {createRef, useEffect} from 'react';
// @ts-ignore
import {ClientCredentialsAuthProvider, StaticAuthProvider} from '@twurple/auth';
import {RootStateOrAny, useSelector} from "react-redux";
import 'moment/locale/it';
import 'animate.css';
import {SocialLogin} from "./SocialLogin";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Api from "./Api";
import Moment from "moment";
import {Actions} from "./Actions";
import {copy, role, showSelect0} from "./Utils";
import {Button0} from "./Button0";

export default function CorsoInfo() {
    const [currCorso, setCurrCorso] = React.useState<any>(null);
    const [trainers, setTrainers] = React.useState<any>(null);
    const [clubs, setClubs] = React.useState<any>(null);
    const [corsi, setCorsi] = React.useState<any>(null);
    const [showSelector, setShowSelector] = React.useState<boolean>(false);
    const root = useSelector((state: RootStateOrAny) => state);
    const [showDays, setshowDays] = React.useState<boolean>(false);
    let location = useLocation();
    let {guid} = useParams();
    /*    let {week} = useParams();*/
    let inizio = Moment((location.state as any)?.data?.inizio);
    let navigate = useNavigate();
    let divRef = createRef<HTMLDivElement>();
    const days = [
        {text: "Lunedì", value: 1},
        {text: "Martedì", value: 2},
        {text: "Mercoledì", value: 3},
        {text: "Giovedì", value: 4},
        {text: "Venerdì", value: 5},
        {text: "Sabato", value: 6},
        {text: "Domenica", value: 0}
    ];
    let hours: { text: string; value: string; }[] = [];
    let start = Moment().set("minute", 0).set("hour", 8);
    for (let i = 0; i <= (50); i++) {
        hours.push({text: start.format("HH:mm"), value: start.format("HH:mm")});
        start = start.add(15, "minutes");
    }
    let posti: { text: number; value: number; }[] = [];
    for (let i = 0; i <= (50); i++) {
        posti.push({text: i, value: i});
    }
    useEffect(() => {
        (async () => {
            if (guid == "nuovo")
                setCurrCorso({});
            else {
                let corsiInfo = await Api.corsoinfo({guid: guid});
                if (corsiInfo.giorno == null)
                    corsiInfo.giorno = 0;
                setCurrCorso(corsiInfo);
            }
            setCorsi(await Api.corsi());
            setClubs(await Api.clubs());
        })();
    }, [])
    useEffect(() => {
        (async () => {
            if (root?.main?.Login) {
                let dbTrainers = await Api.trainers();
                if (role(root) == "trainer")
                    dbTrainers = dbTrainers.filter((x: { email: any; }) => x.email == root?.main?.Login?.email);
                setTrainers(dbTrainers);
                setCorsi(await Api.corsi());
            }
        })();
    }, [root?.main?.Login])

    function canEdit() {
        if (guid == "nuovo")
            return true;
        if (role(root) == "admin")
            return true;
        if (role(root) == "trainer")
            return currCorso.trainer_email == root?.main?.Login?.email;
        return false;
    }

    return (
        <>

            <SocialLogin/>

            <Actions back={"/"}/>

            {currCorso && trainers &&
                <>
                    <div className={"bg-accent p-4 text-white uppercase text-2xl"}
                         onClick={() => {
                             if (canEdit())
                                 showSelect0({
                                     data: corsi.map((x: { guid: any; nome: any; }) => ({
                                         value: x.guid,
                                         text: x.nome.toUpperCase()
                                     })),
                                     onClick: (e) => {
                                         setCurrCorso({
                                             ...currCorso,
                                             corso: e,
                                         });
                                     },
                                     selected: currCorso.corso,
                                 });
                         }}>
                        {currCorso.corso ? corsi?.filter((x: { guid: any; }) => x.guid == currCorso.corso)[0].nome : "<corso>"}
                    </div>
                    {guid != "nuovo" &&
                        <div ref={divRef}
                             dangerouslySetInnerHTML={{__html: (currCorso.dsc)}}
                             suppressContentEditableWarning={true}
                             contentEditable={canEdit()}
                             className={"item0"}>
                        </div>
                    }
                    <div className={"item1"}
                         onClick={() => {
                         }}>
                        <div className={""}>
                            <i className="fa-solid fa-hourglass"/> {currCorso.durata ?? 60} minuti
                        </div>
                        <div className={""}
                             onClick={() => {
                                 if (canEdit())
                                     showSelect0({
                                         data: posti,
                                         onClick: (e) => {
                                             setCurrCorso({
                                                 ...currCorso,
                                                 posti: e,
                                             });
                                         },
                                         selected: currCorso.posti,
                                     });
                             }}
                        >
                            <i className="fa-solid fa-user-group"/> {currCorso.posti ?? "<posti>"} posti totali
                        </div>
                    </div>
                    <div className={"item0"}
                         onClick={() => {
                             if (canEdit())
                                 showSelect0({
                                     data: days,
                                     onClick: (e) => {
                                         setCurrCorso({
                                             ...currCorso,
                                             giorno: e,
                                         });
                                     },
                                     selected: currCorso.giorno,
                                 });
                         }}>
                        Ogni {currCorso.giorno != null ? days.filter(x => x.value == currCorso.giorno)[0].text : "<ricorrenza>"}
                    </div>
                    <div className={"item1"}
                         onClick={() => {
                             if (canEdit())
                                 showSelect0({
                                     data: hours,
                                     onClick: (e) => {
                                         setCurrCorso({
                                             ...currCorso,
                                             ora: e,
                                         });
                                     },
                                     selected: currCorso.ora?.slice(0, 5),
                                 });
                         }}>
                        alle {currCorso.ora ? currCorso.ora.slice(0, 5) : "<orario>"}
                    </div>
                    <div className={"item0 text-2xl capitalize"} onClick={() => {
                        if (canEdit())
                            showSelect0({
                                data: trainers.map((x: { email: any; name: any; }) => ({
                                    value: x.email,
                                    text: x.name.toUpperCase()
                                })),
                                onClick: (e) => {
                                    setCurrCorso({
                                        ...currCorso,
                                        trainer_email: e,
                                    });
                                },
                                selected: currCorso.trainer_email,
                            });
                    }}>
                        <div className={"text-gray-400"}>istruttore</div>
                        {canEdit() && (trainers && currCorso && currCorso.trainer_email ? trainers.filter((x: { email: any; }) => x.email == currCorso.trainer_email)[0].name : "<trainer>")}
                        {!canEdit() && (currCorso?.trainer_email ? currCorso.trainer_name : "<trainer>")}
                    </div>
                    <div className={"item1 text-2xl capitalize"} onClick={() => {
                        if (canEdit())
                            showSelect0({
                                data: clubs.map((x: { guid: any; display: any; }) => ({
                                    value: x.guid,
                                    text: x.display.toUpperCase()
                                })),
                                onClick: (e) => {
                                    setCurrCorso({
                                        ...currCorso,
                                        owner_guid: e,
                                    });
                                },
                                selected: currCorso.owner_guid,
                            });
                    }}>
                        <div className={"text-gray-400"}>club</div>
                        {canEdit() && (clubs && currCorso && currCorso.owner_guid ? clubs.filter((x: { guid: any; }) => x.guid == currCorso.owner_guid)[0].display : "<club>")}
                        {!canEdit() && (currCorso?.club ? currCorso.club : "<club>")}
                    </div>
                    {canEdit() &&
                        <div className={"flex justify-between p-4"}>
                            <Button0
                                onClick={async () => {
                                    if (
                                        currCorso.giorno == null
                                        || currCorso.ora == null
                                        || currCorso.trainer_email == null
                                        || currCorso.corso == null
                                        || currCorso.owner_guid == null
                                        || currCorso.posti == null
                                    ) {
                                        alert("compila tutti i campi");
                                        return false;
                                    }
                                    const corsoToSave = copy(currCorso);
                                    corsoToSave.dsc = divRef.current?.innerText;
                                    const newGuid = await Api.savecorso(corsoToSave);
                                    if (guid == "nuovo") {
                                        navigate(`/info/${newGuid}`);
                                    } else
                                        window.location.reload();
                                }}
                                text={"salva"}
                                icon={<i className="fa-solid fa-cloud-arrow-up"/>}
                            />
                            {guid != "nuovo" &&
                                <Button0
                                    type={"red"}
                                    onClick={async () => {
                                        if (!window.confirm("Confermi?"))
                                            return;
                                        if (await Api.deletecorso({guid: guid}))
                                            navigate("/", {state: location.state});
                                    }}
                                    text={"elimina"}
                                    icon={<i className="fa-solid fa-trash-can-arrow-up"/>}
                                />
                            }
                        </div>
                    }
                    {/*                    <div className={""}>
                     {currCorso.mine &&
                     <Button0
                     type={"red"}
                     onClick={async () => {
                     if (!window.confirm("Confermi?"))
                     return;
                     const data = copy(currCorso);
                     data.corso = corsi?.filter((x: { guid: any; }) => x.guid == currCorso.corso)[0].nome;
                     data.week = week;
                     data.inizio = ToInizio(data);
                     const duration = Moment.duration(data.inizio.diff(Moment())).asHours();
                     if (duration <= 1) {
                     alert("Ahi ahi, non puoi disdire una prenotazione così vicino all'inizio del corso");
                     return false;
                     }
                     if (await Api.sprenota(data) != null) {
                     window.location.reload();
                     }
                     }}
                     text={"Disdici"}
                     icon={<i className="fa-solid fa-right-from-bracket"/>}
                     />
                     }
                     </div>*/}
                </>
            }


        </>
    );
}
