import React from "react";

export function Title(props: { title: string; icon: any; }) {
    return <div className={"p-4 bg-accent text-2xl text-white flex flex-row justify-between"}>
        <div className={""}>
            {props.title}
        </div>
        <div className={""}>
            {props.icon}
        </div>
    </div>;
}