import React from "react";
import {To, useLocation, useNavigate} from "react-router-dom";

export function Actions(props: { back?: To; }) {
    let navigate = useNavigate();
    let location = useLocation();
    return <div className={"bg-700 flex flex-row justify-between p-4 uppercase text-lg text-gray-200"}>
        <div className={"flex flex-row allCenter cursor-pointer"} onClick={() => {
            if (props.back)
                navigate(props.back, {state: location.state});
            else
                window.history.back()
        }}>
            <div className={""}>
                <i className="fa-solid fa-arrow-left-long"/>
            </div>
            <div className={"ml-2"}>
                indietro
            </div>
        </div>
        <div className={"flex flex-row"} onClick={() => {
            window.location.reload();
        }}>
            <div className={""}>
                ricarica
            </div>
            <div className={"ml-2"}>
                <i className="fa-solid fa-arrow-rotate-right"/>
            </div>
        </div>
    </div>;
}