import React from "react";

export function SettingsItemBool(props: {
    onChange: any;
    value: boolean;
    title: string;
}) {

    const [item, setItem] = React.useState<boolean>(props.value);

    return <div className={"mt-1 bg-gray-400 flex flex-row content-center items-center justify-between"}>
        <div className={"p-4"}>
            {props.title}
        </div>
        {item &&
            <div className={"p-4 bg-accent cursor-pointer text-accent-rev"} onClick={() => {
                setItem(false);
                if (props.onChange)
                    props.onChange(false);
            }}>
                ATTIVO
            </div>
        }
        {!item &&
            <div className={"p-4 bg-600 cursor-pointer"} onClick={() => {
                setItem(true);
                if (props.onChange)
                    props.onChange(true);
            }}>
                DISATTIVO
            </div>
        }
    </div>;
}

