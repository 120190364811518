import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Moment from "moment-timezone";
import "moment/locale/it";
import "animate.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./Home";
import CorsoDettagli from "./CorsoDettagli";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {
  setConnection,
  setLogin,
  setNotificationsGrant,
  setNotifier,
} from "./Redux";
import { MessagePrenota } from "./Utils";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Api from "./Api";
import { Settings } from "./Settings";
import { Header } from "./Header";
import CorsoInfo from "./CorsoInfo";
import "tw-elements";
import "./theme_gray.css";
import ListaUtenti from "./ListaUtenti";

const firebaseConfig = {
  apiKey: "AIzaSyDuSDDrTxTH1JXmZzF-CZZIYsB4pK-aaEY",
  authDomain: "prenotacorsi-d69f6.firebaseapp.com",
  projectId: "prenotacorsi-d69f6",
  storageBucket: "prenotacorsi-d69f6.appspot.com",
  messagingSenderId: "586633050644",
  appId: "1:586633050644:web:be38d591d9e89fb26fc501",
};
export default function App() {
  const dispatch = useDispatch();
  const root = useSelector((state: RootStateOrAny) => state);
  useEffect(() => {
    (async () => {
      const theme = localStorage.getItem("theme");
      // @ts-ignore
      if (theme == "purple") import("./theme_purple.css");
      // @ts-ignore
      if (theme == "black") import("./theme_black.css");
      Moment.locale("it");
      setTimeout(async () => {
        if (await Api.isbanned()) {
          alert(
            "il tuo account è stato bannato da questa applicazione. Saluti ;)",
          );
          window.location.href = "https://www.google.it";
        }
      }, 300);
      dispatch(setLogin(await Api.getlogin()));
      setTimeout(() => {
        if (
          !(!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        ) {
          const connect = new HubConnectionBuilder()
            .withUrl(
              !process.env.NODE_ENV || process.env.NODE_ENV === "development"
                ? "https://localhost:5001/hubs/chat"
                : "/hubs/chat",
            )
            .withAutomaticReconnect()
            .build();
          dispatch(setConnection(connect));
        }
      });
    })();
  }, []);
  useEffect(() => {
    (async () => {
      Notification.requestPermission().then(function (permission) {
        dispatch(setNotificationsGrant(permission));
        if (Notification.permission == "granted") {
          if (root?.main?.Login?.email) {
            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging();
            getToken(messaging, {
              vapidKey:
                "BCSCwOSA_PuNPedFO-co0U_PMF0c1zsGfR0tOSFvNk0BdqyOFRn9Z-S1CqAi32zTLR9fOg3ZTgbzN62T_rIXtjo",
            })
              .then(async (currentToken) => {
                if (currentToken) {
                  await Api.savetoken({ token: currentToken });
                } else {
                }
              })
              .catch((err) => {});
          }
        } else {
        }
      });
    })();
  }, [root?.main?.Login]);
  useEffect(() => {
    (async () => {
      if (root?.main?.Connection) {
        root?.main?.Connection.start()
          .then(async () => {
            root?.main?.Connection.on(
              "MessagePrenota",
              async (message: any) => {
                /*             if (Notification.permission == 'granted') {
                             var data = JSON.parse(message);
                             navigator.serviceWorker.getRegistration().then(function (reg) {
                             // @ts-ignore
                             reg.showNotification('Nuova Prenotazione', {
                             data: "https://fitactive.kymera.cloud/dettagli/" + data.guid + "/" + data.week,
                             body: data.from.toUpperCase() + " ha prenotato per " + data.corso.toUpperCase() + " [" + Moment(data.inizio).format("DD MMM HH:mm").toUpperCase() + "]",
                             icon: '/android-chrome-192x192.png',
                             })
                             });
                             }*/
                dispatch(
                  setNotifier({
                    time: Moment(),
                    key: "MessagePrenota",
                    data: JSON.parse(message),
                  }),
                );
              },
            );
          })
          .catch((error: any) => alert(JSON.stringify(error)));
      }
    })();
  }, [root?.main?.Connection]);
  return (
    <React.Fragment>
      <Header />

      <section>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dettagli/:guid/:week" element={<CorsoDettagli />} />
            <Route path="/listautenti" element={<ListaUtenti />} />
            <Route path="/info/:guid" element={<CorsoInfo />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
        <>
          <div
            className={
              "overlay0 hidden z-0 fixed top-0 left-0 w-screen h-screen bg-black opacity-80"
            }
          />
          <div
            className={
              "bg-300 drawer0 hidden h-50vh overflow-y-auto w-screen fixed bottom-0 left-0"
            }
          />
        </>
      </section>
    </React.Fragment>
  );
}
