import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Actions} from "./Actions";
import {SocialLogin} from "./SocialLogin";
import Api from "./Api";
import {copy, hasGrants, showSelect0, trim} from "./Utils";
import {SettingsItemBool} from "./SettingsItemBool";
import {Title} from "./Title";

export function Settings(props: any) {
    const root = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const [settings, setSettings] = React.useState<any>(null);
    useEffect(() => {
        (async () => {
            setSettings(await Api.usersettings());
        })();
    }, [])
    let giorni: { text: number; value: number; }[] = [];
    for (let i = 0; i <= (30); i++) {
        giorni.push({text: i, value: i});
    }
    return <>

        <SocialLogin/>

        <Actions/>

        <Title icon={<i className="fa-solid fa-gear"/>} title={"impostazioni"}/>

        {root?.main?.Login?.role && settings &&
            <>
                {hasGrants("admin", root) &&
                    <SettingsItemBool
                        value={settings.not_type.includes("PREN_TUTTE")}
                        title={"Notifiche per tutti i corsi"}
                        onChange={async (value: any) => {
                            if (!value) {
                                var cSettings = copy(settings);
                                cSettings.not_type = cSettings.not_type.replace("PREN_TUTTE", "");
                                setSettings(cSettings);
                                await Api.saveusersettings(cSettings);
                            }
                            if (value) {
                                var cSettings = copy(settings);
                                cSettings.not_type = trim(cSettings.not_type, ";") + ";PREN_TUTTE";
                                setSettings(cSettings);
                                await Api.saveusersettings(cSettings);
                            }
                        }}
                    />
                }

                {hasGrants("trainer", root) &&
                    <SettingsItemBool
                        value={settings.not_type.includes("PREN_MIE")}
                        title={"Notifiche per i miei corsi"}
                        onChange={async (value: any) => {
                            if (!value) {
                                var cSettings = copy(settings);
                                cSettings.not_type = cSettings.not_type.replace("PREN_MIE", "");
                                setSettings(cSettings);
                                await Api.saveusersettings(cSettings);
                            }
                            if (value) {
                                var cSettings = copy(settings);
                                cSettings.not_type = trim(cSettings.not_type, ";") + ";PREN_MIE";
                                setSettings(cSettings);
                                await Api.saveusersettings(cSettings);
                            }
                        }}/>
                }

                {hasGrants("user", root) &&
                    <SettingsItemBool
                        value={settings.not_type.includes("AVVISO_CORSI")}
                        title={"Avvisi inizio corsi"}
                        onChange={async (value: any) => {
                            if (!value) {
                                var cSettings = copy(settings);
                                cSettings.not_type = cSettings.not_type.replace("AVVISO_CORSI", "");
                                setSettings(cSettings);
                                await Api.saveusersettings(cSettings);
                            }
                            if (value) {
                                var cSettings = copy(settings);
                                cSettings.not_type = trim(cSettings.not_type, ";") + ";AVVISO_CORSI";
                                setSettings(cSettings);
                                await Api.saveusersettings(cSettings);
                            }
                        }}/>
                }

                {hasGrants("trainer", root) &&
                    <div className={"p-4 mt-1 bg-gray-400 flex flex-row content-center items-center justify-between"}
                         onClick={() => {
                             if (hasGrants("trainer", root))
                                 showSelect0({
                                     data: giorni,
                                     onClick: async (e) => {
                                         var cSettings = copy(settings);
                                         cSettings.ban_days = e;
                                         setSettings(cSettings);
                                         await Api.saveusersettings(cSettings);
                                     },
                                     selected: settings.ban_days,
                                 });
                         }}
                    >
                        <div className={""}>
                            giorni di ban
                        </div>
                        <div className={""}>
                            {settings.ban_days ?? "<ban_days>"}
                        </div>
                    </div>
                }

                {hasGrants("trainer", root) &&
                    <div className={"p-4 mt-1 bg-gray-400 flex flex-row content-center items-center justify-between"}
                         onClick={() => {
                             if (hasGrants("trainer", root))
                                 showSelect0({
                                     data: giorni,
                                     onClick: async (e) => {
                                         var cSettings = copy(settings);
                                         cSettings.ban_warnings = e;
                                         setSettings(cSettings);
                                         await Api.saveusersettings(cSettings);
                                     },
                                     selected: settings.ban_warnings,
                                 });
                         }}
                    >
                        <div className={""}>
                            warning in un mese per il ban
                        </div>
                        <div className={""}>
                            {settings.ban_warnings ?? "<ban_warnings>"}
                        </div>
                    </div>
                }

                {hasGrants("trainer", root) &&
                    <div className={"p-4 mt-1 bg-gray-400 flex flex-row content-center items-center justify-between"}
                         onClick={() => {
                             if (hasGrants("trainer", root))
                                 showSelect0({
                                     data: giorni,
                                     onClick: async (e) => {
                                         var cSettings = copy(settings);
                                         cSettings.hours_for_book = e * 24;
                                         setSettings(cSettings);
                                         await Api.saveusersettings(cSettings);
                                     },
                                     selected: settings.hours_for_book / 24,
                                 });
                         }}
                    >
                        <div className={""}>
                            quanti giorni prima è possibile prenotare un corso
                        </div>
                        <div className={""}>
                            {settings.hours_for_book / 24 ?? "<hours_for_book>"}
                        </div>
                    </div>
                }

                <div className={"p-4 flex allCenter"}>
                    <div className={"m-2 h-16 w-16 bg-orange-500 rounded-full"} onClick={() => {
                        localStorage.removeItem("theme");
                        window.location.reload();
                    }}/>
                    <div className={"m-2 h-16 w-16 bg-purple-500 rounded-full"} onClick={() => {
                        localStorage.setItem("theme", "purple");
                        window.location.reload();
                    }}/>
                    <div className={"m-2 h-16 w-16 bg-black rounded-full"} onClick={() => {
                        localStorage.setItem("theme", "black");
                        window.location.reload();
                    }}/>
                </div>

            </>
        }


    </>
}