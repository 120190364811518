import React from "react";

export function Button0(props: { text: string; icon: any; onClick: any; type?: undefined | "red" }) {
    let bgColor = "bg-500";
    if (props.type == "red"){
        bgColor = "bg-red-500";
    }
    return <div className={"inline-block whitespace-nowrap flex flex-row allCenter cursor-pointer"}>
        <div className={"hover:bg-orange-500 text-sm " + bgColor +  " uppercase text-gray-200 rounded-full px-4 py-2"} onClick={()=>{
            props.onClick();
        }}>
            {props.text}
            <div className={"inline-block ml-2"}>
                {props.icon}
            </div>
        </div>
    </div>;
}