import React, {useEffect} from 'react';
// @ts-ignore
import {ClientCredentialsAuthProvider, StaticAuthProvider} from '@twurple/auth';
import 'moment/locale/it';
import 'animate.css';
import {SocialLogin} from "./SocialLogin";
import Api from "./Api";
import Moment from "moment";
import SwipeableViews from "react-swipeable-views";
import {ItemOption} from "./ItemOption";
import {Actions} from "./Actions";
import {Button0} from "./Button0";

export default function ListaUtenti() {
    const [utenti, setutenti] = React.useState<any>(null);
    const [global, setglobal] = React.useState<any>(null);
    const [loading, setloading] = React.useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])
    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [global])

    async function reload() {
        setloading(true);
        setutenti(await Api.listautenti({global: global}));
        setloading(false);
    }

    return (
        <>

            <SocialLogin/>

            <Actions back={"/"}/>

            {loading &&
                <div className={"text-center p-4 pt-5"}>
                    <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0 text-gray-500"
                         role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }

            <div className={"flex allCenter p-4"}>
                <Button0
                    onClick={async () => {
                        setglobal(window.prompt("RICERCA"));
                    }}
                    text={"cerca un utente"}
                    icon={<i className="fa-solid fa-magnifying-glass"/>}
                />
            </div>

            <div className={"relative"}>
                {utenti?.map((o: any, i: any) => {
                    return (
                        <SwipeableViews key={i} disableLazyLoading={true} enableMouseEvents>
                            <div
                                className={"mb-0.5 text-gray-200 h-28 p-4 flex justify-between items-center justify-center" + (i % 2 == 0 ? " bg-600 " : " bg-600 ")}>
                                <div className={"flex flex-row allCenter"}>
                                    <div className={"text-3xl"}>
                                        {(i + 1)}
                                    </div>
                                    <div>
                                        <div
                                            className={"text-xl capitalize ml-3" + (o.warning ? " line-through " : "")}>
                                            {o.name.toLowerCase()} {o.is_guest ? "*" : ""}
                                        </div>
                                        {o.sub_exp != null &&
                                            <div className={"text-gray-900 bg-accent text-sm ml-3 px-2 rounded"}>
                                                abbonamento scade {Moment(o.sub_exp).format("dddd D MMM YYYY")}
                                            </div>
                                        }
                                        {o.sub_exp == null &&
                                            <div className={"text-gray-900 bg-gray-100 text-sm ml-3 px-2 rounded"}>
                                                non ha nessun abbonamento
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    {
                                        // @ts-ignore
                                        <img referrerPolicy="no-referrer"
                                             className="inline-block h-14 w-14 rounded-full ring-4 ring-gray-300"
                                             src={o.image}/>
                                    }
                                </div>
                            </div>
                            <div className={"h-28 select-none"}>
                                <div
                                    className={"text-lg text-white relative uppercase h-28 flex flex-row w-full"}>
                                    <ItemOption text={"imposta data scadenza"}
                                                bg={"bg-accent"}
                                                onClick={async () => {
                                                    const giorno = window.prompt("GIORNO") as string;
                                                    const mese = window.prompt("MESE") as string;
                                                    const anno = window.prompt("ANNO", "2022") as string;
                                                    const date = Moment()
                                                        .set('year', parseInt(anno))
                                                        .set('month', parseInt(mese) - 1)
                                                        .set('date', parseInt(giorno));
                                                    if (await Api.impostadata({date: date, email: o.email}) != null) {
                                                        await reload();
                                                    }
                                                }}
                                    />
                                    <ItemOption text={"elimina abbonamento"}
                                                bg={"bg-red-500"}
                                                onClick={async () => {
                                                    if (await Api.impostadata({date: null, email: o.email}) != null) {
                                                        await reload();
                                                    }
                                                }}
                                    />
                                </div>
                            </div>

                        </SwipeableViews>
                    )
                })}
            </div>

        </>
    );
}
