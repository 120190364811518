import React, {useEffect} from "react";
import Moment from "moment-timezone";
import SwipeableViews from "react-swipeable-views";
import Api from "./Api";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {setPreview} from "./Redux";
import {copy, hasGrants, MessagePrenota} from "./Utils";
import {useNavigate} from "react-router-dom";
import {ItemOption} from "./ItemOption";
import {itocorsi} from "./Interfaces";

export function CorsiItem(props: { c: itocorsi, i: number, onPrenota?: (arg0: any) => void; }) {
    const [index, setIndex] = React.useState<any>(0);
    const [prenotato, setPrenotato] = React.useState<any>(false);
    const [sospeso, setSospeso] = React.useState<any>(false);
    const [tot, setTot] = React.useState<any>(0);
    const [prenotabile, setprenotabile] = React.useState<boolean>(false);
    const [offset, setoffset] = React.useState<number>(72);
    let terminato = props.c.inizio <= Moment();
    let pieno = props.c.max <= props.c.tot;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    const duration = Moment.duration(props.c.inizio.diff(Moment())).asHours();
    useEffect(() => {
        (async () => {
            setPrenotato(props.c.mine);
            setSospeso(props.c.status == "suspended");
            setTot(props.c.tot);
            if (props.i == 0 && root?.main?.Preview)
                setTimeout(() => {
                    setIndex(1);
                    setTimeout(() => {
                        setIndex(0);
                        dispatch(setPreview(false));
                    }, 1000);
                }, 500);
            setprenotabile(duration > 0 && duration < props.c.hours_for_book);
        })();
    }, [props.c])
    return (
        <SwipeableViews style={{overflow: "hidden"}} disableLazyLoading={true} onChangeIndex={(i) => {
            setIndex(i)
        }} index={index} enableMouseEvents>
            <div className={"border-t-2 border-gray-300 w-full relative overflow-hidden"}>

                <div
                    className={"z-10 relative corsi-item-height px-4 flex justify-between items-center" + (terminato ? "" : "")}>
                    <div className={"flex flex-col shrink justify-center content-start items-start"}>
                        <div className="text-gray-200 capitalize text-lg mb-0.5">{props.c.trainer}</div>
                        <div
                            className="text-accent font-bold text-2xl uppercase whitespace-nowrap truncate mb-1">{props.c.corso}</div>
                        <div className="text-gray-200 flex flex-row content-center items-center">
                            <div className={""}><i className="fa-solid fa-clock"/></div>
                            <div className={"ml-2 whitespace-nowrap"}>{props.c.inizio.format("ddd DD MMM")}</div>
                            <div
                                className={"whitespace-nowrap ml-2 text-sm bg-accent text-white py-0.5 px-1"}> {props.c.inizio.format("HH:mm")}</div>
                        </div>
                        <div
                            className={"px-1 py-0 text-gray-900 text-xs bg-300 truncate mt-1.5"}>
                            {props.c.club_name}
                        </div>
                    </div>
                    <div className={"absolute left-1/2 top-1/2 pl-16 -translate-x-1/2 -translate-y-1/2"}>
                        <div className={"flex flex-row allCenter text-white text-2xl opacity-10"}>
                            <i className="fa-solid fa-hand-point-left text-3xl"/>
                            <div className={"lowercase ml-2"}>scorri</div>
                        </div>
                    </div>
                    <div className={"flex flex-col content-end items-end justify-end"}>
                        <div className={"text-gray-400 text-6xl"}>
                            {prenotato || terminato || sospeso ? "" : (props.c.max - tot)}
                        </div>
                        {(!sospeso && !terminato && !prenotato) &&
                            <div
                                className={"text-gray-300 text-md whitespace-nowrap"}>
                                inizia {props.c.inizio.fromNow()}
                            </div>
                        }
                        {!sospeso && terminato &&
                            <div
                                className={"text-gray-300 text-lg whitespace-nowrap"}>
                                corso terminato
                            </div>
                        }
                        {!sospeso && prenotato && !terminato &&
                            <div
                                className={"text-accent-rev text-sm p-2 bg-accent text-black whitespace-nowrap"}>
                                corso prenotato
                            </div>
                        }
                        {sospeso &&
                            <div
                                className={"text-gray-300 text-sm p-2 bg-red-500 text-black whitespace-nowrap"}>
                                corso sospeso
                            </div>
                        }
                    </div>
                </div>

                <div className={"z-1 w-full bg-500 absolute top-0 left-0 corsi-item-height"}>
                </div>
                <div className={"z-2 bg-600 absolute top-0 left-0 corsi-item-height"}
                     style={{width: (tot * 100 / props.c.max) + "%"}}>
                </div>

            </div>

            <div className={"corsi-item-height select-none"}>
                <div
                    className={"text-lg text-white relative top-0.5 uppercase corsi-item-height flex flex-row w-full"}>
                    {!root?.main?.Login?.email &&
                        <>
                            <ItemOption text={"devi eseguire l'accesso per prenotare"}
                                        bg={"bg-500"}/>
                        </>
                    }
                    {!sospeso && !terminato && pieno &&
                        <React.Fragment>
                            <ItemOption text={"non ci sono più posti"}
                                        bg={"bg-500"}/>
                        </React.Fragment>
                    }
                    {terminato && !sospeso &&
                        <React.Fragment>
                            <ItemOption text={"il corso è terminato"}
                                        bg={"bg-500"}/>
                        </React.Fragment>
                    }
                    {hasGrants("user", root) && sospeso &&
                        <React.Fragment>
                            <ItemOption text={"il corso è sospeso"}
                                        bg={"bg-500"}/>
                        </React.Fragment>
                    }
                    {prenotabile && hasGrants("user", root) && !sospeso && !pieno && root?.main?.Login && !terminato && !prenotato &&
                        <ItemOption text={"prenota"}
                                    bg={"bg-accent"}
                                    onClick={async () => {
                                        const data = copy(props.c);
                                        if (await Api.prenota(data) != null) {
                                            setIndex(0);
                                            setPrenotato(true);
                                            setTot(tot + 1);
                                            data.from = root?.main?.Login?.name;
                                            await MessagePrenota(JSON.stringify(data), root);
                                        }
                                    }}/>
                    }
                    {!prenotabile && hasGrants("user", root) && !sospeso && !pieno && root?.main?.Login && !terminato && !prenotato &&
                        <ItemOption text={"prenotabile tra " + Math.round(duration - props.c.hours_for_book) + " ore"}
                                    bg={"bg-500"}/>
                    }
                    {hasGrants("user", root) && !sospeso && !terminato && prenotato &&
                        <ItemOption text={"disdici"}
                                    bg={"bg-red-500"}
                                    onClick={async () => {
                                        if (duration <= 1) {
                                            alert("Ahi ahi, non puoi disdire una prenotazione così vicino all'inizio del corso");
                                            return false;
                                        }
                                        if (await Api.sprenota(props.c) != null) {
                                            setIndex(0);
                                            setPrenotato(false);
                                            setTot(tot - 1);
                                        }
                                    }}/>
                    }
                    {hasGrants("trainer", root) &&
                        <>
                            {!sospeso && !terminato &&
                                <ItemOption text={"sospendi"}
                                            bg={"bg-red-500"}
                                            onClick={async () => {
                                                if (await Api.sospendi({
                                                    week: props.c.week,
                                                    guid: props.c.guid
                                                }) != null) {
                                                    setIndex(0);
                                                    setSospeso(true);
                                                }
                                            }}/>
                            }
                            {sospeso && !terminato &&
                                <ItemOption text={"riprendi"}
                                            bg={"bg-green-500"}
                                            onClick={async () => {
                                                if (await Api.riprendi({
                                                    week: props.c.week,
                                                    guid: props.c.guid
                                                }) != null) {
                                                    setIndex(0);
                                                    setSospeso(false);
                                                }
                                            }}/>
                            }
                            <ItemOption text={"iscritti"}
                                        bg={"bg-500"}
                                        onClick={async () => {
                                            navigate(`dettagli/${props.c.guid}/${props.c.week}`, {
                                                state: {
                                                    data: copy(props.c),
                                                    ref: "/",
                                                    scroll: window.scrollY
                                                }
                                            });
                                        }}/>
                        </>
                    }
                    <ItemOption text={"info"}
                                bg={"bg-blue-500"}
                                onClick={async () => {
                                    console.log(`/info/${props.c.guid}`);
                                    navigate(`/info/${props.c.guid}`, {
                                        state: {
                                            data: copy(props.c),
                                            ref: "/",
                                            scroll: window.scrollY
                                        }
                                    });
                                }}/>
                </div>
            </div>

        </SwipeableViews>
    );
}