import React, {HTMLProps} from 'react';
import Moment from "moment";
import {useDispatch} from "react-redux";
import {itocorsi} from "./Interfaces";

export function copy(object: any | null) {
    if (object == null)
        return null;
    return JSON.parse(JSON.stringify(object));
}

export function timezone(object: any | null) {
    if (object == null)
        return null;
    return object.replace("+01", "+00").replace("+02", "+00");
}

export function ToInizio(corso: any) {
    return Moment()
        .day(corso.giorno)
        .week(corso.week)
        .set('hour', corso.ora.substr(0, 2))
        .set('minute', corso.ora.substr(3, 2))
        .set('second', 0)
        .set('millisecond', 0);
}

export function ToCorsi2(data: any, mine: boolean): itocorsi[] {
    data.map((c: any, i: any) => {
        c.inizio = ToInizio(c);
    });
    if (mine)
        data = data.filter((x: { inizio: any; }) => x.inizio > Moment());
    data = data.sort((a: { inizio: Date; }, b: { inizio: Date; }) => (a.inizio > b.inizio) ? 1 : -1);
    return data;
}

export function hasRole(pRole: string, root: any) {
    return role(root) == pRole;
}

export function role(root: any) {
    return root?.main?.Login?.role;
}

export function hasGrants(pRole: string, root: any) {
    if (pRole == null)
        return true;
    let accountRole = role(root);
    if (pRole == "user")
        return true;
    if (pRole == "trainer" && accountRole == "trainer")
        return true;
    if (pRole == "trainer" && accountRole == "admin")
        return true;
    if (pRole == "admin" && accountRole == "admin")
        return true;
    return false;
}

export async function MessagePrenota(message: any, root: any) {
    if (root?.main?.Connection) await root?.main?.Connection.send("MessagePrenota", message);
}

export function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

export function trim(s: string, c: string) {
    if (c === "]") c = "\\]";
    if (c === "^") c = "\\^";
    if (c === "\\") c = "\\\\";
    return s.replace(new RegExp(
        "^[" + c + "]+|[" + c + "]+$", "g"
    ), "");
}

export function showSelect0(parameters: { data: any[]; onClick: (arg0: any) => void; selected?: any }) {
    let overlay0: HTMLDivElement = document.querySelector(".overlay0") as HTMLDivElement;
    let drawer0: HTMLDivElement = document.querySelector(".drawer0") as HTMLDivElement;
    const centerSelection = () => {
        let selectedDiv = drawer0.querySelector(".selected") as HTMLDivElement;
        drawer0.scrollTop = selectedDiv?.offsetTop +
            selectedDiv?.offsetHeight / 2 - drawer0?.offsetHeight / 2;
    }
    overlay0?.classList.toggle("hidden");
    drawer0?.classList.toggle("hidden");
    parameters.data.map((d: any, i: number) => {
        let div = document.createElement("div");
        div.classList.toggle("p-4");
        div.classList.toggle("item");
        div.classList.toggle("cursor-pointer");
        div.innerHTML = d.text;
        div.onclick = function () {
            const all = drawer0.querySelectorAll(".item") as NodeList;
            all.forEach(function (el) {
                (el as HTMLDivElement).classList.remove("selected");
            });
            div.classList.toggle("selected");
            parameters.onClick(d.value);
            overlay0.click();
        };
        if (parameters.selected?.toString() == d.value?.toString())
            div.classList.toggle("selected");
        drawer0?.append(div);
    });
    if (overlay0)
        overlay0.onclick = function () {
            drawer0.innerHTML = "";
            drawer0?.classList.toggle("hidden");
            overlay0.innerHTML = "";
            overlay0?.classList.toggle("hidden");
        };
    centerSelection();
}