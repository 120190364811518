import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ButtonIcon from "./ButtonIcon";

export function SocialLogin() {
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    let navigate = useNavigate();
    useEffect(() => {
        (async () => {
        })();
    }, [])
    return <div>
        {root?.main?.Login?.email == null &&
            <div className={"bg-300 px-3"}>
{/*                <div className={"inline-table cursor-pointer py-2 text-blue-800"}
                     onClick={() => {
                         document.location.href = '/account/facebook-login?url=' + document.location.href;
                     }}>
                    <i className="m-1 fab fa-facebook"/>
                    Esegui l'accesso con Facebook
                </div>
                <div/>*/}
                <div className={"inline-table cursor-pointer py-2 text-black"}
                     onClick={() => {
                         document.location.href = '/account/google-login?url=' + document.location.href;
                     }}>
                    <i className="m-1 fab fa-google"/>
                    Esegui l'accesso con Google
                </div>
            </div>
        }
        {root?.main?.Login?.email != null &&
            <div className={"bg-300 p-2 px-4 flex flex-row justify-between content-center items-center"}>
                <div>
                    <span>{root?.main?.Login?.name}</span>
                    <span className={"cursor-pointer text-gray-700"} onClick={() => {
                        document.location.href = '/account/logout?url=' + document.location.href;
                    }}>
                  {" (esci)"}
                </span>
                </div>
                <div className={"flex flex-row allCenter"}>
                    <div className="flex space-x-2 justify-center">
                        <ButtonIcon
                            icon={"fas fa-cog"}
                            onClick={() => {
                                navigate("/settings");
                            }}/>
                    </div>
                    <div className="ml-4 flex -space-x-1 overflow-hidden">
                        <img className="inline-block h-10 w-10 rounded-full"
                             src={root?.main?.Login?.image}/>
                    </div>
                </div>

            </div>
        }
    </div>;
}