import React from "react";

export function ItemOption(props: { onClick?: () => void; bg: string; text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
    return <div
        className={"" + props.bg + " w-full flex items-center content-center justify-center text-center"}>
        <div
            className={(props.onClick ? " cursor-pointer " : "")}
            onClick={() => {
                if (props.onClick)
                    props.onClick();
            }}>
            {props.text}
        </div>
    </div>;
}