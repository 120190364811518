import React, {useEffect} from "react";
import Moment from "moment";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Api from "./Api";
import {useLocation} from "react-router-dom";
import {setClub, setDay} from "./Redux";

export function CorsiHeader() {
    const [days, setDays] = React.useState<any>([]);
    const [clubs, setClubs] = React.useState<any>();
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    let location = useLocation();
    useEffect(() => {
        (async () => {
            let localDays = [];
            for (let m = Moment(); m.isBefore(Moment().add(14, "days")); m.add(1, "days")) {
                localDays.push(Moment(m.format()));
            }
            setDays(localDays);
            setClubs(await Api.clubs());
            const localData = (location.state as any)?.data?.inizio;
            if (localData != null) {
                dispatch(setDay(Moment(localData)));
            } else if (root?.main?.Day == null) {
                dispatch(setDay(Moment()));
            }
        })();
    }, [])
    useEffect(() => {
        (async () => {
            const club = (location.state as any)?.data?.club;
            if (clubs && club)
                dispatch(setClub(clubs.filter((x: { guid: any; }) => x.guid == club)[0]));
            else if (clubs) {
                dispatch(setClub(clubs[0]))
            }
        })();
    }, [clubs])
    useEffect(() => {
        (async () => {
            let interval = window.setInterval(() => {
                let selected: any = document.querySelector(".selected");
                if (selected != null) {
                    clearInterval(interval);
                    let container: any = document.querySelector(".div-days");
                    container.scrollLeft = selected.offsetLeft +
                        selected.offsetWidth / 2 - container.offsetWidth / 2;
                }
            }, 1);
        })();
    }, [root?.main?.Day])
    useEffect(() => {
        (async () => {
            let interval = window.setInterval(() => {
                let selected: any = document.querySelector(".selected-club");
                if (selected != null) {
                    clearInterval(interval);
                    let container: any = document.querySelector(".div-clubs");
                    container.scrollLeft = selected.offsetLeft +
                        selected.offsetWidth / 2 - container.offsetWidth / 2;
                }
            }, 1);
        })();
    }, [root?.main?.Club])
    return <div>
        <div
            className={"div-clubs overflow-x-auto w-full flex flex-row bg-700"}>
            {
                clubs?.map((o: any, i: number) => {
                    return (
                        <div key={i}
                             onClick={(e) => {
                                 dispatch(setClub(o));
                                 dispatch(setDay(Moment(root?.main?.Day.add(1, "seconds"))));
                             }}
                             className={"text-white p-4 whitespace-nowrap" + (root?.main?.Club?.guid == o?.guid ? " bg-accent selected-club" : " bg-700 ")}>
                            <div className={"text-2xl font-bold capitalize"}>
                                {o.display.toLowerCase().replace("fitactive", "")}
                            </div>
                            <div className={"text-xs uppercase"}>
                                {o.addr.toLowerCase()}
                            </div>
                        </div>
                    )
                })
            }
        </div>
        <div className="div-days select-none flex overflow-x-auto">
            {root?.main?.Login?.email &&
                <div
                    className={"whitespace-nowrap items-center flex flex-col cursor-pointer" + (root?.main?.Day == -1 ? " bg-accent " : " bg-700 ") + " p-4 text-white uppercase font-bold"}
                    onClick={(e) => {
                        dispatch(setDay(-1));
                    }}
                >
                    <div>MIE PRENOTAZIONI</div>
                    <div
                        className={"whitespace-nowrap text-xs text-gray-200"}>
                        questa settimana
                    </div>
                </div>
            }
            {
                days.map((d: any, i: any) => {
                    return (
                        <div key={i}
                             className={"aaa whitespace-nowrap items-center flex flex-col cursor-pointer" + (root?.main?.Day != -1 && root?.main?.Day?.format("MM DD") == d?.format("MM DD") ? " bg-accent selected " : " bg-600 ") + " p-4 text-white uppercase font-bold"}
                             onClick={(e) => {
                                 dispatch(setDay(d));
                             }}
                        >
                            <div>{d?.format("dddd")}</div>
                            <div
                                className={"whitespace-nowrap text-xs text-gray-200"}>{d?.format("DD MMM")}</div>
                        </div>
                    )
                })
            }
        </div>

    </div>
}