import axios from 'axios';

async function baseCall(url: string, dispatch: any, root: any) {
    const headers = {
        headers: { Authorization: `Bearer ${root?.main?.User?.token}` },
    };
    const startTime: Date = new Date();
    const res = await axios.get<any>(url, headers);
    if (res.data.error != null) {
        // console.log(url);
        // console.log(res.data.error);
        alert(res.data.error);
        return null;
    }

    const endTime: Date = new Date();
    if (typeof res.data === 'object')
        res.data.time = endTime.getTime() - startTime.getTime();
    // console.log(url, res.data);
    return res.data ?? true;
}

async function baseCallPost(url: string, item: any, root: any) {
    // console.log(url, item);
    const headers = {
        headers: { Authorization: `Bearer ${root?.main?.User?.token}` },
    };
    const res = await axios.post<any>(url, item, headers);
    // if (res.data.error != null) {
    //     alert(res.data.error);
    //     return null;
    // }
    return res.data ?? true;
}

export const get = async (
    endpoint: string,
    filters: any,
    dispatch: any,
    root?: any
) => {
    let str = '';
    if (filters != null)
        for (const key in filters) {
            if (str != '') {
                str += '&';
            }
            if (filters[key] != null)
                str += key + '=' + encodeURIComponent(filters[key]);
        }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        return await baseCall(
            `https://localhost:5001${endpoint}?${str}`,
            null,
            root
        );
    else return await baseCall(`${endpoint}?${str}`, null, root);
};

export const post = async (endpoint: string, entity: any, root?: any) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        return await baseCallPost(
            `https://localhost:5001${endpoint}`,
            entity,
            root
        );
    else return await baseCallPost(`${endpoint}`, entity, root);
};
