import React from "react";

export default function ButtonIcon(props: {
    icon: string;
    onClick: () => void;
}) {
    return <div className={"relative"}>
        <button type="button"
                onClick={() => {
                    props.onClick();
                }}
                className="inline-block rounded-full bg-accent text-white leading-normal uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-9 h-9">
            <i className={"text-accent-rev text-lg " + (props.icon)}/>
        </button>
    </div>;
}