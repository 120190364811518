import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDumbbell} from "@fortawesome/free-solid-svg-icons";
import React, {createRef, useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

export function Header(props: any) {

    let menu: any = createRef();

    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        (async () => {

            /* window.onclick = function () {
                 menu.current.classList.add("hidden");
                 menu.current.classList.remove("animate__animated");
                 menu.current.classList.remove("animate__fadeInRight");
             }*/

        })();
    }, [])

    return <nav className={"select-none p-4 bg-white"}>
        <div className={"flex justify-between content-center items-center"}>
            <div onClick={() => {
              window.location.href = "/";
            }}>
                FitActive <span className={"text-gray-400"}>prenotazione corsi</span>
            </div>
            <div
                /* onClick={(e) => {
                     e.stopPropagation();
                     menu.current.classList.toggle("hidden");
                     menu.current.classList.toggle("animate__animated");
                     menu.current.classList.toggle("animate__fadeInRight");
                 }}*/
                className={"cursor-pointer"}>
                <FontAwesomeIcon
                    className={""}
                    size={"lg"} icon={faDumbbell}/>
            </div>
        </div>

        {/* <menu ref={menu}
              className={"z-50 shadow-md bg-white h-full hidden fixed right-0 top-0 select-none animate__faster"}>
            <div className={"p-4"}>
                  {corsiOrari.map((m: any, i: any) => {
                            return (
                                <div className={"p-2"}>dsadasdsad</div>
                            )
                        })}
            </div>
        </menu>*/}

    </nav>;
}