import React, {useEffect} from 'react';
// @ts-ignore
import {ClientCredentialsAuthProvider, StaticAuthProvider} from '@twurple/auth';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import 'moment/locale/it';
import 'animate.css';
import {SocialLogin} from "./SocialLogin";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Api from "./Api";
import Moment from "moment";
import SwipeableViews from "react-swipeable-views";
import {ItemOption} from "./ItemOption";
import {Actions} from "./Actions";
import {timezone, ToCorsi2} from "./Utils";

export default function CorsoDettagli() {
    const [iscritti, setIscritti] = React.useState<any>(null);
    const [currCorso, setCurrCorso] = React.useState<any>(null);
    let {week} = useParams();
    let {guid} = useParams();
    useEffect(() => {
        (async () => {
            setCurrCorso(ToCorsi2(
                await Api.tocorsi({
                    guid: guid,
                    week: week,
                }),
                (window as any).currentDate == -1,
            )[0]);
        })();
    }, [])

    async function reload() {
        if (currCorso)
            setIscritti(await Api.iscritti({
                week: week,
                guid: guid
            }));
    }

    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [currCorso])
    return (
        <>

            <SocialLogin/>

            <Actions back={"/"}/>

            {!currCorso &&
                <div className={"text-center p-4 pt-5"}>
                    <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0 text-gray-500"
                         role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }

            {currCorso &&
                <div
                    className={"relative p-4 bg-accent text-white flex flex-row justify-between content-center items-center"}>
                    <div className={""}>
                        <div className={"text-3xl uppercase"}>
                            {currCorso.corso}
                        </div>
                        <div className={"flex flex-row content-center items-center"}>
                            <div className={"text-sm uppercase"}>
                                {Moment(currCorso.inizio).format("ddd D MMM HH:mm")}
                            </div>
                            <div className={"ml-2 text-2xl capitalize"}>
                                {currCorso.trainer?.toLowerCase()}
                            </div>
                        </div>
                    </div>
                    <div className={"text-5xl"}>
                        {currCorso.tot}
                    </div>
                </div>
            }

            <div className={"relative"}>
                {iscritti?.sort((a: { date: Date; }, b: { date: Date; }) => (a.date > b.date) ? 1 : -1).map((o: any, i: any) => {
                    return (
                        <SwipeableViews key={i} disableLazyLoading={true} enableMouseEvents>
                            <div
                                className={"mb-0.5 text-gray-200 h-28 p-4 flex justify-between items-center justify-center" + (i % 2 == 0 ? " bg-600 " : " bg-600 ")}>
                                <div className={"flex flex-row allCenter"}>
                                    <div className={"text-3xl"}>
                                        {(i + 1)}
                                    </div>
                                    <div>
                                        <div
                                            className={"text-xl capitalize ml-3" + (o.warning ? " line-through " : "")}>
                                            {o.name.toLowerCase()} {o.is_guest ? "*" : ""}
                                        </div>
                                        <div className={"text-gray-400 text-sm ml-3"}>
                                            prenotato {Moment(o.date).format("dddd D MMM HH:mm")}
                                        </div>
                                        {o.sub_exp != null &&
                                            <div className={"text-gray-900 bg-accent text-sm ml-3 px-2 rounded"}>
                                                abbonamento scade {Moment(o.sub_exp).format("dddd D MMM YYYY")}
                                            </div>
                                        }
                                        {o.sub_exp == null &&
                                            <div className={"text-gray-900 bg-gray-100 text-sm ml-3 px-2 rounded"}>
                                                non ha nessun abbonamento
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    {
                                        // @ts-ignore
                                        <img referrerPolicy="no-referrer"
                                             className="inline-block h-14 w-14 rounded-full ring-4 ring-gray-300"
                                             src={o.image}/>
                                    }
                                </div>
                            </div>
                            <div className={"h-28 select-none"}>
                                <div
                                    className={"text-lg text-white relative uppercase h-28 flex flex-row w-full"}>
                                    <ItemOption text={"elimina"}
                                                bg={"bg-red-600"}
                                                onClick={async () => {
                                                    if (await Api.eliminaiscritto(o) != null) {
                                                        await reload();
                                                    }
                                                }}
                                    />
                                    {!o.warning &&
                                        <ItemOption text={"non si è presentato"}
                                                    bg={"bg-yellow-600"}
                                                    onClick={async () => {
                                                        if (await Api.warning({
                                                            corsi_utenti_guid: o.corsi_utenti_guid,
                                                            email: o.email,
                                                            week: o.week
                                                        }) != null) {
                                                            await reload();
                                                        }
                                                    }}
                                        />
                                    }
                                    {o.warning &&
                                        <ItemOption text={"si è presentato"}
                                                    bg={"bg-green-600"}
                                                    onClick={async () => {
                                                        if (await Api.unwarning({
                                                            corsi_utenti_guid: o.corsi_utenti_guid,
                                                            email: o.email,
                                                            week: o.week
                                                        }) != null) {
                                                            await reload();
                                                        }
                                                    }}
                                        />
                                    }
                                </div>
                            </div>

                        </SwipeableViews>
                    )
                })}
            </div>

            <div className={"p-4 bg-500 flex allCenter"}>
                <div className={"uppercase text-gray-200 text-md cursor-pointer"} onClick={async () => {
                    const nome = prompt("Inserisci NOME e COGNOME dell'ospite");
                    if (nome == null)
                        return;
                    if (!window.confirm("confermi " + nome.toUpperCase() + " ?"))
                        return;
                    if (await Api.aggiungiospite({
                        guid: guid,
                        email: nome,
                        week: week
                    }) != null) {
                        await reload();
                    }
                }}>
                    <div className={"flex flex-row allCenter"}>
                        <div className={"text-2xl"}>
                            <i className="fa-solid fa-user-plus"/>
                        </div>
                        <div className={"ml-4 text-lg"}>
                            aggiungi un ospite
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
