import React, {useEffect} from "react";
import Api from "./Api";
import {hasGrants, MessagePrenota, timezone, ToCorsi2} from "./Utils";
import {CorsiItem} from "./CorsiItem";
import Moment from "moment";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Button0} from "./Button0";
import {itocorsi} from "./Interfaces";

export function CorsiList() {
    const [corsiOrari, setCorsiOrari] = React.useState<itocorsi[]>([]);
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    let location = useLocation();
    let navigate = useNavigate();
    useEffect(() => {
        (async () => {
        })();
    }, [])
    useEffect(() => {
        (async () => {
            if (root?.main?.Day) {
                (window as any).currentDate = root?.main?.Day;
                await reload();
            }
        })();
    }, [root?.main?.Day])
    useEffect(() => {
        (async () => {
            if (root?.main?.Notifier?.key == "MessagePrenota") {
                await reload();
            }
        })();
    }, [root?.main?.Notifier])

    async function reload() {
        let week = (window as any).currentDate == -1 ? Moment().week() : (window as any).currentDate.week();
        setCorsiOrari([]);
        setCorsiOrari(ToCorsi2(
                await Api.tocorsi({
                    club: root?.main?.Club?.guid,
                    day: (window as any).currentDate == -1 ? null : (window as any).currentDate.format(),
                    week: week,
                    mine: (window as any).currentDate == -1,
                }) as itocorsi[],
                (window as any).currentDate == -1,
            ) as itocorsi[]
        );
    }

    return <div>
        {
            corsiOrari && corsiOrari.filter((x: { owner: boolean; }) => root?.main?.Filters?.corsi == "miei" ? x.owner : true).map((c: any, i: any) => {
                return <CorsiItem i={i} c={c} key={i} onPrenota={async () => {
                    await reload();
                }}/>
            })
        }
        {
            corsiOrari.length == 0 &&
            <>
                <div className={"text-center p-4 pt-5"}>
                    <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0 text-gray-500"
                         role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </>
        }
        {hasGrants("trainer", root) &&
            <div className={"flex allCenter p-4"}>
                <Button0
                    onClick={() => {
                        navigate(`/info/nuovo`, {
                            state: {
                                data: {},
                                ref: "/",
                                scroll: window.scrollY
                            }
                        });
                    }}
                    text={"aggiungi un nuovo corso"}
                    icon={<i className="fa-solid fa-plus"/>}
                />
            </div>
        }

    </div>;
}