import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Api from "./Api";

export function Stats(props: any) {

    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);

    const [utentiCount, setUtentiCount] = React.useState<any>(null);
    const [clients, setClients] = React.useState<any>(0);

    useEffect(() => {
        (async () => {

            setUtentiCount(await Api.utenticount());

            setClients(await Api.clientscount());

        })();
    }, [])

    return <div className="w-full">
        <div className="bg-700 p-4 flex justify-between items-center">
            <div>
                <div className="text-gray-300">Iscritti Totali</div>
                <div className="text-accent font-bold text-3xl">{utentiCount}</div>
                <div className="text-gray-300">di cui {clients + 1} online</div>
            </div>
            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 stroke-white" fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"/>
                </svg>
            </div>
        </div>
    </div>;
}