import React from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import {getOS} from "./Utils";

export function Alerts(props: any) {

    const root = useSelector((state: RootStateOrAny) => state);

    return <>
        <div>
            {root?.main?.NotificationsGrant == "granted" &&
                <>
                </>
            }
            {root?.main?.NotificationsGrant != "granted" &&
                <div className={"p-4 flex flex-row items-center text-gray-300 bg-600"}>
                    <div className={""}><i className="fa-solid fa-bell-slash"/></div>
                    <div className={"ml-4"}>
                        <div className={""} onClick={() => {
                            Notification.requestPermission().then((result) => {
                                if (result === 'granted') {
                                    window.location.reload();
                                }
                            });
                        }}>clicca qui per provare ad attivare le notifiche [{root?.main?.NotificationsGrant}]
                        </div>
                        {getOS() == "iOS" &&
                            <a className={"text-xs"} target={"_blank"}
                               href={"https://support.apple.com/it-it/HT201925#:~:text=Vai%20su%20Impostazioni%20e%20tocca,del%20riepilogo%20programmato%20delle%20notifiche."}>
                                clicca qui per la guida
                            </a>
                        }
                        {getOS() != "iOS" &&
                            <a className={"text-xs"} target={"_blank"}
                               href={"https://support.google.com/chrome/answer/3220216?hl=it&co=GENIE.Platform%3DAndroid&oco=1"}>
                                clicca qui per la guida
                            </a>
                        }
                    </div>

                </div>
            }
        </div>
    </>
}