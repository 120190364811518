import React from "react";
import { get, post } from "./DbConfig";
import { iaggiungiospite, iprenota } from "./Interfaces";

export const Api = {
  usersettings: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/usersettings", filters, dispatch, root),
  corsiorari: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/corsiorari", filters, dispatch, root),
  corsiutenti: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/corsiutenti", filters, dispatch, root),
  clientscount: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/clientscount", filters, dispatch, root),
  trainerscount: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/trainerscount", filters, dispatch, root),
  corsi: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/corsi", filters, dispatch, root),
  utenticount: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/utenticount", filters, dispatch, root),
  trainers: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/trainers", filters, dispatch, root),
  prenota: (filters?: iprenota, dispatch?: any, root?: any) =>
    post("/api/prenota", filters, dispatch),
  aggiungiospite: (filters?: iaggiungiospite, dispatch?: any, root?: any) =>
    post("/api/aggiungiospite", filters, dispatch),
  deletecorso: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/deletecorso", filters, dispatch),
  savecorso: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/savecorso", filters, dispatch),
  warning: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/warning", filters, dispatch),
  unwarning: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/unwarning", filters, dispatch),
  sprenota: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/sprenota", filters, dispatch),
  eliminaiscritto: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/eliminaiscritto", filters, dispatch),
  getlogin: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/getlogin", filters, dispatch, root),
  tocorsi: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/tocorsi", filters, dispatch, root),
  clubs: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/clubs", filters, dispatch, root),
  sospendi: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/sospendi", filters, dispatch, root),
  riprendi: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/riprendi", filters, dispatch, root),
  iscritti: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/iscritti", filters, dispatch, root),
  savetoken: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/savetoken", filters, dispatch, root),
  send: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/send", filters, dispatch, root),
  saveusersettings: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/saveusersettings", filters, dispatch),
  corsoinfo: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/corsoinfo", filters, dispatch, root),
  isbanned: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/isbanned", filters, dispatch, root),
  listautenti: (filters?: any, dispatch?: any, root?: any) =>
    get("/api/listautenti", filters, dispatch, root),
  impostadata: (filters?: any, dispatch?: any, root?: any) =>
    post("/api/impostadata", filters, dispatch),
};
export default Api;
