import React, {createRef, useEffect} from 'react';
// @ts-ignore
import {ClientCredentialsAuthProvider, StaticAuthProvider} from '@twurple/auth';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import 'moment/locale/it';
import 'animate.css';
import {CorsiHeader} from "./CorsiHeader";
import {CorsiList} from "./CorsiList";
import {Stats} from "./Stats";
import {SocialLogin} from "./SocialLogin";
import {Alerts} from "./Alerts";
import {useNavigate} from "react-router-dom";
import {Assistenza} from "./Assistenza";
import {Filters} from "./Filters";
import {getOS, hasGrants} from "./Utils";
import Moment from "moment";

export default function Home() {
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    const [currDate, setCurrDate] = React.useState<any>(null);
    let menu: any = createRef();
    let navigate = useNavigate();
    useEffect(() => {
        (async () => {
        })();
    }, [])
    return (
        <React.Fragment>

            <Stats/>

            {getOS() != "iOS" &&
                <Alerts/>
            }

            <SocialLogin/>

            {hasGrants("admin", root) &&
                <div className={"uppercase text-center text-sm p-4 bg-500 text-accent-rev"} onClick={() => {
                    navigate("/listautenti");
                }}>
                    IMPOSTA ABBONAMENTO UTENTE
                </div>
            }

            {root?.main?.Login?.exp_sub != null &&
                <div className={"uppercase text-center text-sm p-4 bg-500 text-accent-rev"}>
                    IL TUO ABBONAMENTO SCADE IL
                    <span className={"bg-accent px-1 py-0.5 ml-2 rounded"}>
                        {Moment(root?.main?.Login?.exp_sub).format("DD MMM YYYY")}
                    </span>
                </div>
            }

            <Filters/>

            <CorsiHeader/>

            <CorsiList/>

            <Assistenza/>

        </React.Fragment>
    );
}
