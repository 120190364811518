import React, {useEffect} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {setFilters} from "./Redux";
import {hasGrants} from "./Utils";
import {Button0} from "./Button0";

export function Filters() {

    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        (async () => {

        })();
    }, [])

    return <>
        {hasGrants("trainer", root) &&
            <>
                <div className={""}>
                    <div
                        className={"overflow-x-auto whitespace-nowrap flex flex-row bg-700 text-gray-200 text-sm uppercase"}>
                        <div
                            className={"p-4 cursor-pointer" + (root?.main?.Filters?.corsi == "miei" ? " bg-accent " : "")}
                            onClick={() => {
                                dispatch(setFilters({...root?.main?.Filters, corsi: "miei"}));
                            }}
                        >
                            solo i corsi che gestisco
                        </div>
                        <div
                            className={"p-4 cursor-pointer" + (root?.main?.Filters?.corsi == "tutti" ? " bg-accent " : "")}
                            onClick={() => {
                                dispatch(setFilters({...root?.main?.Filters, corsi: "tutti"}));
                            }}
                        >
                            tutti i corsi
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}