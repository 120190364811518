import React from "react";

export function Assistenza() {
  return (
    <div
      className={
        "mt-0.5 p-4 bg-700 flex justify-between content-center items-center cursor-pointer"
      }
      onClick={() => {
        document.location.href =
          "https://chat.whatsapp.com/JHBR9E8hx2iHSp81Qgo0Vq";
      }}
    >
      <div className={"text-gray-300 text-lg"}>
        clicca qui per ricevere assistenza
      </div>
      <div className={"text-gray-300"}>
        <i className="fa-brands fa-whatsapp text-2xl font-bold"></i>
      </div>
    </div>
  );
}
