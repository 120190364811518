import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
    Login: null,
    Preview: true,
    Connection: null,
    Notifier: null,
    NotificationsGrant: false,
    Filters: {
        corsi: "tutti"
    },
    Club: null,
    Day: null,
};
export const counterSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        setLogin: (state, action) => {
            return {...state, Login: action.payload};
        },
        setPreview: (state, action) => {
            return {...state, Preview: action.payload};
        },
        setConnection: (state, action) => {
            return {...state, Connection: action.payload};
        },
        setNotifier: (state, action) => {
            return {...state, Notifier: action.payload};
        },
        setNotificationsGrant: (state, action) => {
            return {...state, NotificationsGrant: action.payload};
        },
        setFilters: (state, action) => {
            return {...state, Filters: action.payload};
        },
        setClub: (state, action) => {
            return {...state, Club: action.payload};
        },
        setDay: (state, action) => {
            return {...state, Day: action.payload};
        },
    },
});
export const {
    setLogin,
    setPreview,
    setConnection,
    setNotifier,
    setNotificationsGrant,
    setFilters,
    setClub,
    setDay,
} = counterSlice.actions;
export default counterSlice.reducer;